import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { TableEventData } from "../../table/table/table.component";

@Injectable()
export class LocationsApi {
  private readonly requestUrl: string = "/api/Locations";
  constructor(private http: HttpClient) {}

  getLocations(filtersAndOrdering: TableEventData): Observable<Locations> {
    return this.http.post<Locations>(
      `${this.requestUrl}/GetLocations`,
      filtersAndOrdering
    );
  }

  deleteLocation(locationId: number): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${locationId}`);
  }

  upsertLocation(location: Location): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, location);
  }

  exportExcel(): Observable<Blob> {
    return this.http.get<Blob>(`${this.requestUrl}/ExportExcel`, {
      responseType: "blob" as "json",
    });
  }
}

export interface Locations {
  locations: Location[];
  count: number;
}

export interface Location {
  id: number;
  locationCode: string;
  client: string;
  clientId: number;
  warehouse: string;
  area: string;
  lane: string;
  shelf: string;
  bay: string;
  floor: string;
  position: number;
  description: string;
}

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { TableEventData, Column } from "../../table/table/table.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Box, BoxesApi, Boxes, LocationsAndProducts } from "./boxes-api";
import { Types } from "../../table/filter/filter.component";
import { TableUtils } from "../../table/table-utils";
import { filter, tap, switchMap, catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { UpsertBoxComponent } from "./upsert-box/upsert-box.component";
import { DeleteBoxComponent } from "./delete-box/delete-box.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-boxes",
  templateUrl: "./boxes.component.html",
  styleUrls: ["./boxes.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
})
export class BoxesComponent implements OnInit {
  boxes: BehaviorSubject<Box[]> = new BehaviorSubject<Box[]>([]);
  rowCount: number;
  filtersAndOrdering: TableEventData;
  locsAndProds: LocationsAndProducts;

  loading: boolean = false;

  columns = new BehaviorSubject<Column[]>([
    {
      index: 0,
      field: "productCode",
      headerLabel: "Product",
      type: Types.Text,
    },
    {
      index: 1,
      field: "locationCode",
      headerLabel: "Location",
      type: Types.Text,
    },
    {
      index: 2,
      field: "maximumSupply",
      headerLabel: "Maximum supply",
      type: Types.Number,
    },
    {
      index: 3,
      field: "resupplyQuantity",
      headerLabel: "Resupply quantity",
      type: Types.Number,
    },
    {
      index: 4,
      field: "actions",
      headerLabel: "Actions",
      type: null,
    },
  ]);

  defaultOrederBy: string = "productCode";
  defaultDirection: string = "asc";

  constructor(
    private api: BoxesApi,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    this.filtersAndOrdering = {
      filters: TableUtils.initFilters(this.columns.value),
      orderAndPagination: {
        order: {
          direction: this.defaultDirection,
          orderBy: this.defaultOrederBy,
        },
        pagination: {
          pageNumber: 0,
          pageSize: 10,
        },
      },
    };
    this.loading = true;

    this.api.getBoxes(this.filtersAndOrdering).subscribe((x: Boxes) => {
      this.boxes.next(x.boxes);
      this.rowCount = x.count;
      this.loading = false;
    });

    this.api.GetProductsAndLocations().subscribe((x: LocationsAndProducts) => {
      this.locsAndProds = x;
    });
  }

  ngOnInit() {}

  manageTableEvent(event: TableEventData) {
    this.loading = true;
    this.filtersAndOrdering = event;
    this.api.getBoxes(this.filtersAndOrdering).subscribe((x: Boxes) => {
      this.boxes.next(x.boxes);
      this.rowCount = x.count;
      this.loading = false;
    });
  }

  upsertBox(b: Box) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      box: b,
      update: b != null,
      locations: this.locsAndProds.locations,
      products: this.locsAndProds.products,
    };
    dialogConfig.width = "55vw";
    const dialogRef = this.dialog.open(UpsertBoxComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        filter((box: Box) => box != null),
        tap(() => (this.loading = true)),
        switchMap((updatedBox: Box) => this.api.upsertBox(updatedBox)),
        catchError((x: HttpErrorResponse) => {
          alert("❌");
          this.loading = false;
          return of(0);
        }),
        switchMap(() => this.api.getBoxes(this.filtersAndOrdering))
      )
      .subscribe((x: Boxes) => {
        this.boxes.next(x.boxes);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  deleteBox(b: Box) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      box: b,
    };
    const dialogRef = this.dialog.open(DeleteBoxComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        filter((deleteConfirmed: Boolean) => deleteConfirmed == true),
        tap(() => (this.loading = true)),
        switchMap((x) => this.api.deleteBox(b.locationId, b.productId)),
        catchError((x: HttpErrorResponse) => {
          alert("❌");
          this.loading = false;
          return of(0);
        }),
        switchMap(() => this.api.getBoxes(this.filtersAndOrdering))
      )
      .subscribe((x: Boxes) => {
        this.boxes.next(x.boxes);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  exportExcel(): void {
    this.api.exportExcel().subscribe((file: Blob) => {
      var blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Boxes.xlsx";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    });
  }

  createLabels($event: any): void {
    let files: FileList = $event.target.files;
    this.api
      .createLabels(files.item(0))
      .pipe(
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      )
      .subscribe((file: Blob | null) => {
        if (file) {
          var blob = new Blob([file], {
            type: "application/pdf",
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "new_orders_labels_" +
            this.datePipe.transform(new Date(), "yyyyMMddhhmm") +
            ".pdf";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  }

  extraAction(b: Box) {
    window.open(
      "api/Boxes/GetLabel/" + b.productId + "/" + b.locationId,
      "_blank"
    );
  }
}
